
        var result = require("!!../node_modules/mini-css-extract-plugin/dist/loader.js!../node_modules/css-loader/index.js??ref--10-3!../node_modules/sass-loader/dist/cjs.js??ref--10-4!./custom.scss");

        if (result && result.__esModule) {
            result = result.default;
        }

        if (typeof result === "string") {
            module.exports = result;
        } else {
            module.exports = result.toString();
        }
    